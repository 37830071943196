/*@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap");*/
/*@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap");*/
@import url("https://fonts.googleapis.com/css2?family=Roboto&family=Open+Sans&family=Anton&family=Open+Sans&:wght@500&family=Poppins&display=swap");
/*@import url("https://fonts.googleapis.com/css2?family=Anton&family=Open+Sans:wght@500&display=swap");*/
.markdown > h1,
.markdown > h2,
.markdown > h3,
.markdown > h4 {
  margin-bottom: 1em;
  text-align: left;
  font-size: 1.6em;
  font-weight: bolder;
  font-family: system-ui, Helvetica, Arial, sans-serif;
}

figure > img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 70%;
}
figure > figcaption {
  text-align: center;
}

.markdown {
  margin-left: auto;
  margin-right: auto;
  max-width: 80%;
}

.markdown > p {
  font-size: 1.1em;
  font-family: "Poppins", sans-serif;
}

@media only screen and (max-width: 600px) {

  .markdown{
    margin: 0;
    max-width: 100%;
  }
}
@media only screen and (min-width: 800px) {
  figure > img {
    max-width: 40%;
  }

}