.snippet {
  padding-top: 2em;
  padding-bottom: 2em;
  max-width: 85%;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 600px) {

  .snippet {
    max-width: 100%;
  }
}