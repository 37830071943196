@import url("https://fonts.googleapis.com/css2?family=Neonderthaw&family=The+Nautigal&display=swap");

.LoginForm {
  height: 100vh;
  background: radial-gradient(circle, #0d6efd, rgb(106, 17, 203,1));
}

.form-EP{
  background-color: black !important;
  color: #ffff !important;
}

.form-EP:focus{
  border-color: #ffff !important;
  box-shadow: 0px 0px 0px 1px #ffff !important;
}

.Login-Form {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  padding: 5;
  text-align: center;
}

.title-login {
  font-family: "The Nautigal", cursive;
  margin: 0;
  padding-bottom: 20px;
  font-size: 70px;
}

.button-disabled:disabled{
  cursor: not-allowed;
}