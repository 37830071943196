@import url("https://fonts.googleapis.com/css2?family=Ramaraja&family=Oswald:wght@700&display=swap");

.posts{
    background-color: #f8f9fa;
    border-radius: 20px;
}
@media only screen and (max-width: 1000px) {
    .posts {
        border: none;
    }
}
