@import url(https://fonts.googleapis.com/css2?family=Ramaraja&family=Oswald:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Neonderthaw&family=The+Nautigal&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&family=Open+Sans&family=Anton&family=Open+Sans&:wght@500&family=Poppins&display=swap);
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}



.posts{
    background-color: #f8f9fa;
    border-radius: 20px;
}
@media only screen and (max-width: 1000px) {
    .posts {
        border: none;
    }
}

.separador {
    display: flex;
    justify-content: center;
}
.separador::after {
    content: "";
    position: absolute;
    display: block;
    height: 10px;
    width: 35vw;
    left: 52%;
    border-bottom: 1px solid #1390da;
}
.separador::before {
    content: "";
    position: absolute;
    display: block;
    height: 10px;
    width: 35vw;
    right: 52%;
    border-bottom: 1px solid #1390da;
}

.image-resize {
    overflow: hidden;
    display: flex;
    justify-content: center;
    width: 100% !important;
    height: calc(100vh - 200px) !important;
    object-fit: cover;
    object-position: center center;
    opacity: 0.3;
}
.title-card {
    color: white;
    font-size: 1.5em;
    font-weight: bold;
    font-family: 'Noto Sans', sans-serif;
    text-shadow: 0px 1px 2px #676767;
}
.title-card:hover {
    -webkit-animation: scaleTitle 3.5s forwards;
            animation: scaleTitle 3.5s forwards;
}

.carousel {
    margin-top: 3vh;
    position: relative;
    display: flex;
    width: 100%;
    height: calc(100vh - 200px);
}
.carousel-item {
    justify-content: center;
    width: 100% !important;
    height: 100%;
    background-color: black;
}

@-webkit-keyframes scaleTitle {
    100% {
        color: rgba(255, 255, 255, 0.9);
        font: bold;
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        left: 10px;
    }
}

@keyframes scaleTitle {
    100% {
        color: rgba(255, 255, 255, 0.9);
        font: bold;
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        left: 10px;
    }
}
@media only screen and (max-width: 500px) {
    .carousel {
        height: 50vh;
        margin-top: 2vh;
    }
}


.title-home-card{
    font-family: 'Noto Sans', sans-serif;
    color: #073042;
    font-size: 1.5em;
    font-weight: 600;
    line-height: 1.3em;
    cursor: pointer;
    word-spacing: -0.1em;
    text-align: left;
}
.title-home-card:hover{
    color: #1769e0;
}
.date-home-card{
    font-family: 'Noto Sans', sans-serif;
    color: #073042;
    font-size: 1.2em;
    line-height: 1em;
    font-weight: 400;
}
.description-home-card{
    font-family: 'Noto Sans', sans-serif;
    color: #202124;
    font-size: 1.2em;
    line-height: 1.2em;
    font-weight: 400;
    text-align: justify;
    text-justify: inter-word;
}
.card-content{
    height: 100%;
    width: 100%;
    max-height: 500px;
    box-shadow: #abdcff !important;
    background-color: #fffefe !important;
}
.card-media{
    transition: 0.5s ease;
    height: 200px;
}
.clickable{
    cursor: pointer;
    width: 100%;
    height: 100%;
}
.dot-menu{
    background: #fffefe;
}
@media only screen and (max-width: 1000px) {
    .card-content {
        min-height: 400px;
    }
}

.activo-boton{
    background-color: black;
    text-decoration: none;
    color: #ffff;
    pointer-events: none;
}
.desactivo-boton{
    pointer-events: none !important;
    opacity: 30%;
}
.paginate-container > ul{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
}
.paginate-container > ul > li{
    display: flex;
    height: 18.667px;
    width: 34px;
}
.paginate-container > ul > li a,
.paginate-container > ul > .selected a{
    border-radius: 50%;
    height: 32px;
    width: 32px;
    text-align: center;
    line-height: 32px;
    vertical-align: middle;
    position: absolute;
    z-index: 1;
    align-self: center;
}

.paginate-container > ul > li a:hover,
.selected a:hover{
    background-color: #e4e2e2;
    color: black;
}
.paginate-container > ul li:first-child,
.paginate-container > ul li:last-child{
    background-color: #ffff;
    border: none;
    height: 18.677px;
    width: 52px;
}
.paginate-container > ul li:first-child a,
.paginate-container > ul li:last-child a{
    background-color: #ffff;
    border: 1px solid;
    border-color: black;
    border-radius: 50%;
    height: 48px ;
    width: 48px;
    margin-top: 0;
    position: absolute;
    z-index: 1;
    align-self: center;
}
.paginate-container > ul li:first-child a:hover,
.paginate-container > ul li:last-child a:hover{
    opacity: 1;
    border: 1px solid;
    background-color: #eeeeee;
}

.paginate-container > ul li:last-child a::after {
    content: "";
    display: block;
    z-index: 1;
    background-color: black;
    height: 3px;
    width: 10px;
    border-radius: 3px;
    margin-top: 51%;
    margin-left: 40%;
    -webkit-transform: rotateZ(-45deg);
            transform: rotateZ(-45deg);
}
.paginate-container > ul li:last-child a::before {
    content: "";
    display: block;
    position: absolute;
    background-color: black;
    height: 3px;
    width: 10px;
    margin-top: 40%;
    margin-left: 40%;
    border-radius: 3px;
    -webkit-transform: rotateZ(45deg);
            transform: rotateZ(45deg);

}
.paginate-container > ul li:first-child a::after {
    content: "";
    display: block;
    z-index: 1;
    background-color: black;
    height: 3px;
    width: 10px;
    border-radius: 3px;
    margin-top: 52%;
    margin-left: 40%;
    -webkit-transform: rotateZ(45deg);
            transform: rotateZ(45deg);
}
.paginate-container > ul li:first-child a::before {
    content: "";
    display: block;
    position: absolute;
    background-color: black;
    height: 3px;
    width: 10px;
    margin-top: 39%;
    margin-left: 40%;
    border-radius: 3px;
    -webkit-transform: rotateZ(-45deg);
            transform: rotateZ(-45deg);

}
.LoginForm {
  height: 100vh;
  background: radial-gradient(circle, #0d6efd, rgb(106, 17, 203,1));
}

.form-EP{
  background-color: black !important;
  color: #ffff !important;
}

.form-EP:focus{
  border-color: #ffff !important;
  box-shadow: 0px 0px 0px 1px #ffff !important;
}

.Login-Form {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  padding: 5;
  text-align: center;
}

.title-login {
  font-family: "The Nautigal", cursive;
  margin: 0;
  padding-bottom: 20px;
  font-size: 70px;
}

.button-disabled:disabled{
  cursor: not-allowed;
}

/*@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap");*/
/*@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap");*/
/*@import url("https://fonts.googleapis.com/css2?family=Anton&family=Open+Sans:wght@500&display=swap");*/
.markdown > h1,
.markdown > h2,
.markdown > h3,
.markdown > h4 {
  margin-bottom: 1em;
  text-align: left;
  font-size: 1.6em;
  font-weight: bolder;
  font-family: system-ui, Helvetica, Arial, sans-serif;
}

figure > img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 70%;
}
figure > figcaption {
  text-align: center;
}

.markdown {
  margin-left: auto;
  margin-right: auto;
  max-width: 80%;
}

.markdown > p {
  font-size: 1.1em;
  font-family: "Poppins", sans-serif;
}

@media only screen and (max-width: 600px) {

  .markdown{
    margin: 0;
    max-width: 100%;
  }
}
@media only screen and (min-width: 800px) {
  figure > img {
    max-width: 40%;
  }

}
.snippet {
  padding-top: 2em;
  padding-bottom: 2em;
  max-width: 85%;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 600px) {

  .snippet {
    max-width: 100%;
  }
}
.Container {
  border-radius: 40px;
  margin-bottom: 3em;
  padding-left: 3em;
  padding-right: 3em;
  padding-top: 3em;
  padding-bottom: 20px;
  background-color: #eeeeee;
}

.Image {
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.title {
  margin-top: 3em;
}

.title > h1 {
  text-align: center;
}

.title > p {
  font-size: 1.2em;
  margin-top: 1em;
  text-align: center;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}


.preview {
  border: 1px solid black;
  word-wrap: break-word;
  max-height: 60em;
  overflow-y: scroll;
}

.navbar {
  font-family:Arial, Helvetica, sans-serif;
  box-shadow: 0px 10px 14px -7px #0e180a69;
  background-image: linear-gradient(to right, #0396FF , #ABDCFF);
}

.hide {
  display: none !important;
}
.nav-link {
  color: #000000e0 !important;
  margin: 0 8px;
  margin-top: 0%;
}

.searchIcon{
  border: solid;
  background-color: transparent !important;
  border-color: transparent !important;
  position: absolute;
  margin-left: 9rem;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg)
}
.fa-magnifying-glass{
  color: black !important;
}

.link {
  text-decoration: none !important;
  color: #000000;
  margin-right: 2rem;
}
.myButton-navbar {
  display: inline-block;
  cursor: pointer;
  font-size: 16px;
  color: rgb(255, 254, 254) !important;
  padding: 6px 20px;
  text-decoration: none;
  
}
.myButton-navbar:hover {
  background: linear-gradient(to bottom, #54a770fb 5%, #198754 100%);
  text-shadow: none;
}
.myButton-navbar:active {
  position: relative;
  top: 1px;
}
.myButton-hide{
  visibility: hidden;
}
.btn-navbar{
  margin-left: 65vh;
  width: 70px;
	display:inline-block;
	cursor:pointer;
	font-size:16px;
	padding:5px 20px;
	text-decoration:none;
  color: rgb(255, 254, 254) !important;
}
.btn-navbar:hover {
  background: linear-gradient(to bottom, #54a770fb 5%, #198754 100%);
  text-shadow: none;
}
.btn-hide{
  visibility: hidden;
}

@media (max-width: 992px) {
  .searchIcon{
    margin-left: 40rem;
  }
  .myButton-navbar {
    color: inherit;
    background-color: none;
    background: none;
    border: none;
    box-shadow: none;
  }
  .myButton-navbar:hover {
    background: none;
  }
  .myButton-hide{
    display: none;
    color: #ffffff;
  }
  .btn-navbar{
    margin: 10px;
    color: inherit;
    background-color: none;
    background: none;
    border: none;
    box-shadow: none;
  }
  .btn-navbar:hover {
    background-color: none;
  }
  .btn-hide{
    display: none;
    color: #ffffff;
}
}
@media (max-width: 780px) and (min-width: 548px){
  .searchIcon{
    margin-left: 29rem;
  }
}
@media (max-width: 547px){
  .searchIcon{
    margin-left: 20rem;
  }
}
.footer-section {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.xchel-dev-footer-section {

}
