.navbar {
  font-family:Arial, Helvetica, sans-serif;
  box-shadow: 0px 10px 14px -7px #0e180a69;
  background-image: linear-gradient(to right, #0396FF , #ABDCFF);
}

.hide {
  display: none !important;
}
.nav-link {
  color: #000000e0 !important;
  margin: 0 8px;
  margin-top: 0%;
}

.searchIcon{
  border: solid;
  background-color: transparent !important;
  border-color: transparent !important;
  position: absolute;
  margin-left: 9rem;
  transform: rotateY(180deg)
}
.fa-magnifying-glass{
  color: black !important;
}

.link {
  text-decoration: none !important;
  color: #000000;
  margin-right: 2rem;
}
.myButton-navbar {
  display: inline-block;
  cursor: pointer;
  font-size: 16px;
  color: rgb(255, 254, 254) !important;
  padding: 6px 20px;
  text-decoration: none;
  
}
.myButton-navbar:hover {
  background: linear-gradient(to bottom, #54a770fb 5%, #198754 100%);
  text-shadow: none;
}
.myButton-navbar:active {
  position: relative;
  top: 1px;
}
.myButton-hide{
  visibility: hidden;
}
.btn-navbar{
  margin-left: 65vh;
  width: 70px;
	display:inline-block;
	cursor:pointer;
	font-size:16px;
	padding:5px 20px;
	text-decoration:none;
  color: rgb(255, 254, 254) !important;
}
.btn-navbar:hover {
  background: linear-gradient(to bottom, #54a770fb 5%, #198754 100%);
  text-shadow: none;
}
.btn-hide{
  visibility: hidden;
}

@media (max-width: 992px) {
  .searchIcon{
    margin-left: 40rem;
  }
  .myButton-navbar {
    color: inherit;
    background-color: none;
    background: none;
    border: none;
    box-shadow: none;
  }
  .myButton-navbar:hover {
    background: none;
  }
  .myButton-hide{
    display: none;
    color: #ffffff;
  }
  .btn-navbar{
    margin: 10px;
    color: inherit;
    background-color: none;
    background: none;
    border: none;
    box-shadow: none;
  }
  .btn-navbar:hover {
    background-color: none;
  }
  .btn-hide{
    display: none;
    color: #ffffff;
}
}
@media (max-width: 780px) and (min-width: 548px){
  .searchIcon{
    margin-left: 29rem;
  }
}
@media (max-width: 547px){
  .searchIcon{
    margin-left: 20rem;
  }
}