
.image-resize {
    overflow: hidden;
    display: flex;
    justify-content: center;
    width: 100% !important;
    height: calc(100vh - 200px) !important;
    object-fit: cover;
    object-position: center center;
    opacity: 0.3;
}
.title-card {
    color: white;
    font-size: 1.5em;
    font-weight: bold;
    font-family: 'Noto Sans', sans-serif;
    text-shadow: 0px 1px 2px #676767;
}
.title-card:hover {
    animation: scaleTitle 3.5s forwards;
}

.carousel {
    margin-top: 3vh;
    position: relative;
    display: flex;
    width: 100%;
    height: calc(100vh - 200px);
}
.carousel-item {
    justify-content: center;
    width: 100% !important;
    height: 100%;
    background-color: black;
}

@keyframes scaleTitle {
    100% {
        color: rgba(255, 255, 255, 0.9);
        font: bold;
        transform: scale(1.1);
        left: 10px;
    }
}
@media only screen and (max-width: 500px) {
    .carousel {
        height: 50vh;
        margin-top: 2vh;
    }
}
