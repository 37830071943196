.title-home-card{
    font-family: 'Noto Sans', sans-serif;
    color: #073042;
    font-size: 1.5em;
    font-weight: 600;
    line-height: 1.3em;
    cursor: pointer;
    word-spacing: -0.1em;
    text-align: left;
}
.title-home-card:hover{
    color: #1769e0;
}
.date-home-card{
    font-family: 'Noto Sans', sans-serif;
    color: #073042;
    font-size: 1.2em;
    line-height: 1em;
    font-weight: 400;
}
.description-home-card{
    font-family: 'Noto Sans', sans-serif;
    color: #202124;
    font-size: 1.2em;
    line-height: 1.2em;
    font-weight: 400;
    text-align: justify;
    text-justify: inter-word;
}
.card-content{
    height: 100%;
    width: 100%;
    max-height: 500px;
    box-shadow: #abdcff !important;
    background-color: #fffefe !important;
}
.card-media{
    transition: 0.5s ease;
    height: 200px;
}
.clickable{
    cursor: pointer;
    width: 100%;
    height: 100%;
}
.dot-menu{
    background: #fffefe;
}
@media only screen and (max-width: 1000px) {
    .card-content {
        min-height: 400px;
    }
}
