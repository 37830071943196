.Container {
  border-radius: 40px;
  margin-bottom: 3em;
  padding-left: 3em;
  padding-right: 3em;
  padding-top: 3em;
  padding-bottom: 20px;
  background-color: #eeeeee;
}

.Image {
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.title {
  margin-top: 3em;
}

.title > h1 {
  text-align: center;
}

.title > p {
  font-size: 1.2em;
  margin-top: 1em;
  text-align: center;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

